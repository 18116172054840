<template>
  <div>
    <table-component
      ref="tableComponent"
      :model="model"
      :permissions="{
        create: false,
        edit: true,
        delete: false,
        filter: false,
      }"
      :show-form="false"
      url-edit-form="/orders"
    >
      <template v-slot:actions>
        <b-button
          :variant="onlyConfirmed ? 'primary' : 'outline-primary'"
          @click="showOnlyConfirmed($event)"
        >
          Solo confirmados
        </b-button>
      </template>
    </table-component>
  </div>
</template>

<script>
import TableComponent from '@/dc-it/components/TableComponent.vue'
import Order from '@/dc-it/models/Order'
import { BButton } from 'bootstrap-vue'

export default {
  name: 'CategoryView',
  components: { TableComponent, BButton },
  data() {
    return {
      model: Order,
      onlyConfirmed: false,
    }
  },
  methods: {
    showOnlyConfirmed() {
      if (this.onlyConfirmed) {
        this.onlyConfirmed = false
        this.$refs.tableComponent.filterFields = []
        this.$refs.tableComponent.clearFilter()
      } else {
        this.$refs.tableComponent.filterFields = [
          {
            field: 'status',
            value: 2,
          },
        ]
        this.$refs.tableComponent.applyFilter()
        this.onlyConfirmed = true
      }
    },
  },
}
</script>

<style scoped>

</style>
